import React from 'react';
import { Field, FieldProps } from 'formik';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

interface ICustomYesNoProps {
  name: string;
  label: string;
  error: boolean;
  [key: string]: any;
}

const CustomYesNo: React.FC<ICustomYesNoProps> = ({ name, label, error, ...props }) => {
  return (
    <>
      <Field name={name}>
        {({ form: { getFieldProps, setFieldValue }, field, meta }: FieldProps) => (
          <FormControl
            component="fieldset"
            classes={{ root: 'MuiFormControl-no-border MuiFormControl-radio' }}
          >
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
              row
              aria-label={label}
              {...props}
              {...getFieldProps(name)}
              value={field.value}
              onChange={event => setFieldValue(field.name, event.target.value)}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            {error ? <div className="form__errors">Please select one of the options</div> : ''}
          </FormControl>
        )}
      </Field>
    </>
  );
};

export default CustomYesNo;
