import React from 'react';
import { Field, FieldProps } from 'formik';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

interface ICustomSelectProps {
  name: string;
  label: string;
  values: { [key: string]: string };
  [key: string]: any;
}

const CustomSelect: React.FC<ICustomSelectProps> = ({
  name,
  label,
  customClass,
  values,
  ...props
}) => {
  return (
    <>
      <Field name={name}>
        {({ form: { getFieldProps } }: FieldProps) => (
          <TextField
            select
            fullWidth
            label={label}
            variant="outlined"
            classes={customClass ? { root: customClass } : {}}
            {...getFieldProps(name)}
            {...props}
          >
            {/* <MenuItem value="">- None -</MenuItem> */}
            {Object.keys(values).map(key => (
              <MenuItem key={key} value={key}>
                {values[key]}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Field>
    </>
  );
};

export default CustomSelect;
