import React from 'react';
import { Field, FieldProps } from 'formik';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface ICustomCheckboxProps {
  name: string;
  label: string;
  error: boolean;
  [key: string]: any;
}

const CustomCheckbox: React.FC<ICustomCheckboxProps> = ({ name, label, error, ...props }) => {
  return (
    <>
      <Field name={name}>
        {({ form: { getFieldProps, setFieldValue }, field, meta }: FieldProps) => (
          <>
            <FormControlLabel
              control={<Checkbox value="1" />}
              label={`${label}${props.required ? ' *' : ''}`}
              {...props}
              {...getFieldProps(name)}
              classes={{ root: 'MuiFormControl-no-border MuiFormControl-check' }}
            />
            {error ? <div className="form__errors">Please confirm this</div> : ''}
          </>
        )}
      </Field>
    </>
  );
};

export default CustomCheckbox;
