import React, { useState } from 'react';
import { Formik } from 'formik';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

import CustomSelect from '@components/CustomSelect';
import CustomYesNo from '@components/CustomYesNo';
import CustomCheckbox from '@components/CustomCheckbox';
import CustomTextField from '@components/CustomTextField';

import { GETFORM_URL_ATHENS_EVENT, CONTACT_EMAIL } from '@utils/constants';
import axios from 'axios';

const initValues = {
  company_name: '',
  prefix: 'Mr',
  first_name: '',
  last_name: '',
  address_1: '',
  address_2: '',
  city: '',
  postcode: '',
  phone: '',
  mobile: '',
  email: '',
  pass_no: '',
  pass_name: '',
  pass_nationality: '',
  pass_date_birth: '',
  pass_place_country_birth: '',
  pass_issue_date: '',
  pass_expiry_date: '',
  pass_issue_place: '',
  partner_first_name: '',
  partner_last_name: '',
  partner_pass_no: '',
  partner_pass_name: '',
  partner_pass_nationality: '',
  partner_pass_date_birth: '',
  partner_pass_place_country_birth: '',
  partner_pass_issue_date: '',
  partner_pass_expiry_date: '',
  partner_pass_issue_place: '',
  req_seating: '',
  req_dietary: '',
  req_fish: '',
  medication_needs: '',
  travel_insurance: [],
  covid_pass: '',
  emergency_name: '',
  emergency_phone: '',
};

const prefixValues = {
  Mr: 'Mr',
  Mrs: 'Mrs',
  Miss: 'Miss',
  Ms: 'Ms',
  Dr: 'Dr',
  Prof: 'Prof',
  Rev: 'Rev',
  Fr: 'Fr',
  Sr: 'Sr',
  Rabbi: 'Rabbi',
};

const notRequired = ['address_2', 'postcode', 'phone'];

const validateForm = (values: any, confirmedEmail: string, withPartner: boolean, hideCovid: boolean) => {
  const errors: any = {};
  for (const [key, value] of Object.entries(values)) {
    // test required
    if (value == '' && notRequired.indexOf(key) === -1) {
      if (withPartner || !key.startsWith('partner_')) {
        if (!(key === 'covid_pass' && hideCovid)) {
          errors[key] = 'required';
          continue;
        }        
      }
    }

    // confirm email
    if (key === 'email' && String(value).trim() !== confirmedEmail.trim()) {
      errors[key] = 'emails_not_same';
      continue;
    }

    // email format
    if (
      key == 'email' &&
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(String(value))
    ) {
      errors.email = 'no_email';
    }
  }

  return errors;
};

const printErrors = (errors: any) => {
  for (const [_, value] of Object.entries(errors)) {
    if (value == 'required') {
      return 'Please fill in all the red-marked fields to continue.';
    }
    if (value == 'no_email') {
      return 'Email address is not in proper format, please fix that.';
    }

    if (value === 'emails_not_same') {
      return 'Email address is not same as confirmation email.';
    }
  }
};

type EventFormProps = {
  hideCovid?: boolean;
  getformId?: string;
}

const EventForm: React.FC<EventFormProps> = ({ hideCovid = false, getformId = GETFORM_URL_ATHENS_EVENT}: EventFormProps) => {
  const [formState, setFormState] = useState('open');
  const [withPartner, setWithPartner] = useState(false);
  const [confirmedEmail, setConfirmedEmail] = useState('');

  const handleWithPartner = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWithPartner(event.target.checked);
  };

  const handleConfirmedEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmedEmail(event.target.value);
  };

  async function sendForm(values: any) {
    setFormState('sending');

    values.travel_insurance = '1';

    try {
      const result = await axios({
        url: getformId,
        method: 'post',
        headers: {
          Accept: 'application/json',
        },
        data: values,
      });
      if (result.data && result.data.success) {
        setFormState('thankyou');
      } else {
        console.error('Form submission not successful: ', result.data);
        setFormState('error');
      }
    } catch (err) {
      console.error('Form communication error: ', err);
      setFormState('error');
    }
  }

  return (
    <>
      {formState == 'open' && (
        <>
          <Formik
            initialValues={initValues}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              sendForm(values);
            }}
            validate={(values: any) => validateForm(values, confirmedEmail, withPartner, hideCovid)}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, errors }) => (
              <form className="form" onSubmit={handleSubmit}>
                <p>
                  <em>Please fill in all items marked with *</em>
                </p>
                <div>
                  <CustomTextField
                    name="company_name"
                    label="Company Name"
                    required={true}
                    error={typeof errors.company_name != 'undefined'}
                  />
                  <CustomSelect
                    name="prefix"
                    label="Prefix"
                    required={true}
                    values={prefixValues}
                    error={typeof errors.prefix != 'undefined'}
                  />
                  <CustomTextField
                    name="first_name"
                    label="First Name"
                    required={true}
                    error={typeof errors.first_name != 'undefined'}
                  />
                  <CustomTextField
                    name="last_name"
                    label="Last Name"
                    required={true}
                    error={typeof errors.last_name != 'undefined'}
                  />
                  <CustomTextField
                    name="address_1"
                    label="Address 1"
                    required={true}
                    error={typeof errors.address_1 != 'undefined'}
                  />
                  <CustomTextField
                    name="address_2"
                    label="Address 2"
                    error={typeof errors.address_2 != 'undefined'}
                  />
                  <CustomTextField
                    name="city"
                    label="City"
                    required={true}
                    error={typeof errors.city != 'undefined'}
                  />
                  <CustomTextField
                    name="postcode"
                    label="Postcode"
                    error={typeof errors.postcode != 'undefined'}
                  />
                  <CustomTextField
                    name="phone"
                    label="Phone"
                    type="phone"
                    error={typeof errors.phone != 'undefined'}
                  />
                  <CustomTextField
                    name="mobile"
                    label="Mobile"
                    type="phone"
                    required={true}
                    error={typeof errors.mobile != 'undefined'}
                  />
                  <CustomTextField
                    name="email"
                    label="Email"
                    type="email"
                    required={true}
                    error={typeof errors.email != 'undefined'}
                  />
                  <TextField
                    required
                    fullWidth
                    type="email"
                    id="confirm_email"
                    label="Confirm email address"
                    value={confirmedEmail}
                    onChange={handleConfirmedEmail}
                    variant="outlined"
                  />
                </div>

                <div className="">
                  <h4>Main Registrant's Passport Information</h4>
                  <p>Passport must be valid for 6 months after date of travel.</p>

                  <CustomTextField
                    name="pass_no"
                    label="Passport Number"
                    required={true}
                    error={typeof errors.pass_no != 'undefined'}
                  />
                  <CustomTextField
                    name="pass_name"
                    label="Name as it appears on Passport"
                    helperText="Full Names - e.g. John Michael Brown"
                    required={true}
                    error={typeof errors.pass_name != 'undefined'}
                  />
                  <CustomTextField
                    name="pass_nationality"
                    label="Nationality"
                    helperText="as on passport - e.g. British Citizen"
                    required={true}
                    error={typeof errors.pass_nationality != 'undefined'}
                  />
                  <CustomTextField
                    name="pass_date_birth"
                    label="Date of Birth"
                    helperText="e.g. 30th September 1975"
                    required={true}
                    error={typeof errors.pass_date_birth != 'undefined'}
                  />
                  <CustomTextField
                    name="pass_place_country_birth"
                    label="Place & Country of Birth"
                    helperText="e.g. Manchester, England"
                    required={true}
                    error={typeof errors.pass_place_country_birth != 'undefined'}
                  />
                  <CustomTextField
                    name="pass_issue_date"
                    label="Passport Issue Date"
                    helperText="e.g. 30th August 2016"
                    required={true}
                    error={typeof errors.pass_issue_date != 'undefined'}
                  />
                  <CustomTextField
                    name="pass_expiry_date"
                    label="Passport Expiry Date"
                    helperText="e.g. 30th August 2026"
                    required={true}
                    error={typeof errors.pass_expiry_date != 'undefined'}
                  />
                  <CustomTextField
                    name="pass_issue_place"
                    label="Passport Place of Issue"
                    helperText="Passport Place of Issue"
                    required={true}
                    error={typeof errors.pass_issue_place != 'undefined'}
                  />
                </div>

                <FormControlLabel
                  control={
                    <Switch
                      checked={withPartner}
                      onChange={handleWithPartner}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  classes={{ root: 'MuiFormControlLabel-switch' }}
                  label="I am bringing a partner with me"
                />

                {withPartner ? (
                  <div className="">
                    <h4>Partner's Details</h4>
                    <p>Please complete fully.</p>
                    <div className="">
                      <CustomTextField
                        name="partner_first_name"
                        label="First Name"
                        required={true}
                        error={typeof errors.partner_first_name != 'undefined'}
                      />
                      <CustomTextField
                        name="partner_last_name"
                        label="Last Name"
                        required={true}
                        error={typeof errors.partner_last_name != 'undefined'}
                      />
                      <CustomTextField
                        name="partner_pass_no"
                        label="Passport Number"
                        required={true}
                        error={typeof errors.partner_pass_no != 'undefined'}
                      />
                      <CustomTextField
                        name="partner_pass_name"
                        label="Name as it appears on Passport"
                        helperText="Full Names - e.g. John Michael Brown"
                        required={true}
                        error={typeof errors.partner_pass_name != 'undefined'}
                      />
                      <CustomTextField
                        name="partner_pass_nationality"
                        label="Nationality"
                        helperText="as on passport - e.g. British Citizen"
                        required={true}
                        error={typeof errors.partner_pass_nationality != 'undefined'}
                      />
                      <CustomTextField
                        name="partner_pass_date_birth"
                        label="Date of Birth"
                        helperText="e.g. 30th September 1975"
                        required={true}
                        error={typeof errors.partner_pass_date_birth != 'undefined'}
                      />
                      <CustomTextField
                        name="partner_pass_place_country_birth"
                        label="Place & Country of Birth "
                        helperText="e.g. Manchester, England"
                        required={true}
                        error={typeof errors.partner_pass_place_country_birth != 'undefined'}
                      />
                      <CustomTextField
                        name="partner_pass_issue_date"
                        label="Passport Issue Date"
                        helperText="e.g. 30th August 2016"
                        required={true}
                        error={typeof errors.partner_pass_issue_date != 'undefined'}
                      />
                      <CustomTextField
                        name="partner_pass_expiry_date"
                        label="Passport Expiry Date"
                        helperText="e.g. 30th August 2026"
                        required={true}
                        error={typeof errors.partner_pass_expiry_date != 'undefined'}
                      />
                      <CustomTextField
                        name="partner_pass_issue_place"
                        label="Passport Place of Issue"
                        helperText="e.g. Birmingham, England"
                        required={true}
                        error={typeof errors.partner_pass_issue_place != 'undefined'}
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="">
                  <h4>Personal requirements</h4>
                  <p>Please complete fully.</p>

                  <CustomTextField
                    name="req_seating"
                    label="Preferred Flight Seating"
                    helperText="e.g. window/aisle - cannot be guaranteed - subject to airline co-operation"
                    required={true}
                    error={typeof errors.req_seating != 'undefined'}
                  />
                  <CustomTextField
                    name="req_dietary"
                    label="Personal Dietary Requests"
                    helperText="e.g. Vegetarian, Non shellfish or any food allergies."
                    required={true}
                    error={typeof errors.req_dietary != 'undefined'}
                  />
                  <CustomYesNo
                    name="req_fish"
                    label="Do you eat white fish?"
                    required={true}
                    error={typeof errors.req_fish != 'undefined'}
                  />
                </div>

                <div className="">
                  <h4>Regular Medication Needs and Emergency Contact Information</h4>

                  <CustomTextField
                    name="medication_needs"
                    label="Regular Medication Needs/Disabilities"
                    helperText="please indicate if temporary or permanent"
                    required={true}
                    error={typeof errors.medication_needs != 'undefined'}
                  />

                  <CustomCheckbox
                    name="travel_insurance"
                    label="Please confirm you already have or will be arranging your own personal travel insurance"
                    required={true}
                    error={typeof errors.travel_insurance != 'undefined'}
                  />

                  { !hideCovid && 
                    <CustomYesNo
                      name="covid_pass"
                      label="If asked, do you have an NHS COVID-19 Pass to show as proof of status?"
                      required={true}
                      error={typeof errors.covid_pass != 'undefined'}
                    />
                  }

                  <CustomTextField
                    name="emergency_name"
                    label="Emergency Contact Name"
                    required={true}
                    error={typeof errors.emergency_name != 'undefined'}
                  />
                  <CustomTextField
                    name="emergency_phone"
                    type="phone"
                    label="Emergency Contact Telephone Number"
                    required={true}
                    error={typeof errors.emergency_phone != 'undefined'}
                  />
                </div>

                <div className="form__button-line">
                  {errors && <div className="form__errors">{printErrors(errors)}</div>}
                  <button className="form__button" type="submit">
                    SUBMIT
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </>
      )}
      {formState == 'sending' && (
        <div className="form__notification">
          <p className="progress">Sending your enquiry...</p>
        </div>
      )}
      {formState == 'error' && (
        <div className="form__notification">
          <p className="error">Error while sending form!</p>
          <p className="error">
            Please contact us via e-mail: <a href={'mailto:' + CONTACT_EMAIL}>{CONTACT_EMAIL}</a>
          </p>
        </div>
      )}
      {formState == 'thankyou' && (
        <div className="form__notification">
          <h3>Submission successful</h3>
          <p>Thank you for your enquiry.</p>
          <p>It has been forwarded to the team and will be dealt with as soon as possible.</p>
        </div>
      )}
    </>
  );
};

export default EventForm;
